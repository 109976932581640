import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { CrawlerStatusProduct, Supplier } from '@equipmyschool/emsadminweb-models';
import { CrawlerStatusService } from 'app/server/server.module';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';
import { FilteredDataSource, getSortFn } from 'app/shared/filtered-data-source/filtered-data-source';
import { saveFile } from 'app/shared/save-file';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, AfterViewInit {
  public readonly columnSortMappings = {
    Name: (row: CrawlerStatusProduct) => row.Supplier.Name,
    Expired: (row: CrawlerStatusProduct) => row.Crawled ? row.Expired / row.Crawled : -1,
    NotMatched: (row: CrawlerStatusProduct) => row.Crawled ? row.NotMatched / row.Crawled : -1,
    LastImported: (row: CrawlerStatusProduct) => parseInt(row.LastImported, 10) || 0
  };

  public readonly dataSource = new FilteredDataSource(
    () => this.server.getProducts()
  );
  public readonly displayedColumns = ['Name', 'Crawled', 'Expired', 'NotMatched', 'NotCrawled', 'LastImported'];

  @ViewChild(MatSort) sort: MatSort;

  @UnsubscribeOnDestroy()
  private subscriptions: Subscription[] = [];

  constructor(
    private server: CrawlerStatusService
  ) { }

  ngOnInit(): void {
    this.dataSource.forceUpdate();
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.sort.sortChange.subscribe((val: Sort) => {
      this.dataSource.sortFn = getSortFn(val, this.columnSortMappings);
    }));
  }

  getFile(supplier: Supplier, type: 'MissingPricing' | 'NotMatched' | 'MissingFromWebsite') {
    this.subscriptions.push(this.server['getProducts' + type](supplier.SupplierID).subscribe(res => {
      saveFile(res);
    }));
  }
}
