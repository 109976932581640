import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrawlerStatusSupplier } from '@equipmyschool/emsadminweb-models';

export interface DialogData {
  row: CrawlerStatusSupplier;
}

@Component({
  selector: 'edit-display-name-dialog',
  templateUrl: './edit-display-name-dialog.component.html',
  styleUrls: ['./edit-display-name-dialog.component.scss']
})
export class EditDisplayNameDialogComponent {
  displayName: string;

  constructor(
    public dialogRef: MatDialogRef<EditDisplayNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.displayName = String(data.row.DisplayName);
  }

  onSaveClick(): any {
    this.data.row.DisplayName = this.displayName;
    this.dialogRef.close(this.data.row);
  }

}
