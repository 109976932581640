<div>
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container cdkColumnDef="Supplier">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Supplier</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.SupplierName}}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="DocumentNumber">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Invoice Number</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.DocumentNumber}}
      </mat-cell>
    </ng-container>
        
    <ng-container cdkColumnDef="Currency">
      <mat-header-cell *cdkHeaderCellDef>Currency</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Currency.Code}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="Date">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Date</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Date | date: 'd MMM yyyy'}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="Goods">
      <mat-header-cell *cdkHeaderCellDef>Goods</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Goods | currency: row.Currency.Code}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="Freight">
      <mat-header-cell *cdkHeaderCellDef>Freight</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Freight | currency: row.Currency.Code}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="Tax">
      <mat-header-cell *cdkHeaderCellDef>Tax</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Tax | currency: row.Currency.Code}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="Total">
      <mat-header-cell *cdkHeaderCellDef>Total</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Total | currency: row.Currency.Code}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="Added">
      <mat-header-cell *cdkHeaderCellDef>Added</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Added | date: 'd MMM yyyy'}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="AddedBy">
      <mat-header-cell *cdkHeaderCellDef>Added By</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.AddedBy}}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="View">
      <mat-header-cell *cdkHeaderCellDef>View</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <button mat-icon-button [attr.invoice-number]="row.DocumentNumber" [attr.is-credit]="row.Total < 0" onclick="showInvoice(this, event)" (click)="row.Viewed=true">
          <mat-icon>visibility</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Actions">
      <mat-header-cell *cdkHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <button *ngIf="row.Viewed" mat-icon-button (click)="tryCheck(row)">
          <mat-icon>check</mat-icon>
        </button>
        <button *ngIf="row.Viewed" mat-icon-button (click)="tryDelete(row)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>
