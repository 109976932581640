import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectionDialogComponent, SelectionOption } from 'app/shared/selection-dialog/selection-dialog.component';
import { PurchaseInvoiceService } from 'app/server/server.module';
import { PurchaseInvoiceToCheck } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-check-purchase-invoices',
  templateUrl: './check-purchase-invoices.component.html',
  styleUrls: ['./check-purchase-invoices.component.scss']
})
export class CheckPurchaseInvoicesComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  dataColumns = ['Supplier', 'DocumentNumber', 'Currency', 'Date', 'Goods', 'Freight', 'Tax', 'Total', 'Added', 'AddedBy', 'View', 'Actions'];
  dataSource = new MatTableDataSource<PurchaseInvoiceToCheck>([]);

  constructor(
    private server: PurchaseInvoiceService,
    public dialog: MatDialog
  ) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {    
    this._subscriptions.push(
      this.server.pisToCheck$
      .pipe(
        map((response) => {
          if (response) {              
            if (this.sort) {
              this.dataSource.data = this.dataSource.sortData(response, this.sort);
              this.dataSource.sort = this.sort;
            }
          }
        })
      )
      .subscribe()
    );

    this.server.getInvoicesToCheck()
  }
  
  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  tryCheck(row: PurchaseInvoiceToCheck) {
    this.server.check(Number(row.ID)).subscribe(() => {
      var index = this.dataSource.data.indexOf(row);
      if (index > -1) {
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription();
      }
    });
  }

  tryDelete(row: PurchaseInvoiceToCheck) {
    var reasons = [
      { name: "Invoice is actually a ProForma" },
      { name: "Incorrect Document Posted" },
      { name: "Incorrect Document Number" },
      { name: "Missing Goods" },
      { name: "Incorrect Goods Value" },
      { name: "Incorrect Tax Value" },
      { name: "Missing Tax Value" },
      { name: "Incorrect Freight Value" },
      { name: "Incorrect Discount" },
      { name: "Incorrect Goods included" },
      { name: "Incorrect Invoice Date" },
      { name: "Incorrect Supplier" },
      { name: "Incorrect Currency" },
      { name: "Duplicated" },
      { name: "Re-raised/replaced by Supplier" }
    ];

    const dialogRef = this.dialog.open(SelectionDialogComponent, {data: { title: `Please advise why this PI needs to be deleted?`, options: reasons}});
    dialogRef.afterClosed().subscribe((result: SelectionOption) => {
      if (result) {
        this.server.delete(Number(row.ID), result.name).subscribe(() => {
          var index = this.dataSource.data.indexOf(row);
          if (index > -1) {
            this.dataSource.data.splice(index, 1);
            this.dataSource._updateChangeSubscription();
          }
        });
      }
    });
  }

}
