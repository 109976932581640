<h2 mat-dialog-title>Edit Display Name</h2>
<mat-dialog-content>
  <p>What is the correct Display Name for {{data.row.Name}}?</p>
  <mat-form-field>
    <mat-label>Display Name</mat-label>
    <input matInput [(ngModel)]="displayName" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="onSaveClick()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
