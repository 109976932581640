<div *ngIf="selectedClient$ | async as client">
  <div>
    <mat-toolbar class="mat-elevation-z4">
      <span class="row-1">
        <h1>Proformas to Dispatch - {{client.accountNo}}</h1>
      </span>

      <div class="user">
        <div class="profilepicture">
          <img src={{client.admin.imageUrl}}>
        </div>
        <div class="names">
          <p class="ccs-name">{{client.admin.firstName}}</p>
          <p class="deputy-name">{{client.deputy.firstName}}</p>
        </div>
      </div>

      <span class="row-2">
        <h2>Move a quotation from a quotation to a dispatch to associate</h2>
      </span>
    </mat-toolbar>


    <div class="workspace">
      <div class="grid client" cdkDropListGroup>
        <div class="proforma-list"
        cdkDropList
        (cdkDropListDropped)="dropDisassociate($event)" 
        [cdkDropListData] = "sacrificialArray"
        id="drop-zone"
        >
              <div *ngFor="let proforma of client.proformas" id="proforma-{{proforma.pfiId}}"
              cdkDropList
              (cdkDropListDropped)="dropDisassociate($event)" 
              cdkDropListSortingDisabled 
              [cdkDropListConnectedTo] = "dispatchDropArray"
              [cdkDropListData] = "client.proformas">
                <mat-card class="mat-elevation-z4 proforma" *ngIf="proforma.pfiId">
                  <mat-card-title>
                    <div class="pfiId">{{ proforma.pfiId < 0 ? 'FOC Goods' :'Quotation ' + proforma.pfiId }}</div>
                    <div class="contents">
                      <button matTooltip="Proforma Contents" class="contents" mat-icon-button aria-label="proforma contents" (click)="sharingProformaData(proforma)">
                        <mat-icon svgIcon="looking-glass"></mat-icon>
                      </button>
                    </div>
                    <div class="freightMethod">{{proforma.pfiIncoterm.abbreviation}} {{proforma.pfiFreightMethod.name}}</div>
                  </mat-card-title>
                  <p class="divide--long"></p>
                  <div class="mid-section">
                    <div class="value">{{proforma.pfiValue | currency: proforma.pfiCurrency.currencyCode}}</div>
                    <div class="incoterm-location">Incoterm Location: {{proforma.incotermLocation}}</div>
                    <div class="show-Address">
                      <div class="delivery-address address-format {{hasConsignee((proforma.pfiConsigneeAddress ?? proforma.pfiDeliveryAddress).addressId ?? 0, proforma.pfiDeliveryAddress.addressId)}}"><span class="description">{{proforma.pfiDeliveryAddress.addressDescription}}</span><br> {{proforma.pfiDeliveryAddress.addressDetail}}</div>
                      <div class="consignee-address address-format"><span class="description">{{(proforma.pfiConsigneeAddress ?? proforma.pfiDeliveryAddress).addressDescription}}</span><br> {{(proforma.pfiConsigneeAddress ?? proforma.pfiDeliveryAddress).addressDetail}}</div>
                    </div>    
                    <!-- <div class="delivery-address address-format"><span class="description">{{proforma.pfiDeliveryAddress.addressDescription}}</span><br>{{proforma.pfiDeliveryAddress.addressDetail}}</div> -->
                    <div class="leaving-from">Leaving from: {{proforma.pfiLeavingFromWarehouse.region}}</div>
                  </div>
                  <p class="divide--long"></p>
                  <div class="agent-and-contact">
                    <div class="agent">
                      {{proforma.pfiAgent.companyName ?? "No Agent Listed"}}
                      <span class="contact-grid">
                        <span class="name">{{proforma.pfiAgentContact?.fullName ?? "No Contact Listed"}}</span>
                        <span *ngIf="(proforma.pfiAgentContact?.workEmail?.length ?? 0) > 0" class="email">{{proforma.pfiAgentContact?.workEmail ?? "No Contact Listed"}}</span>
                        <span *ngIf="proforma.pfiAgentContact?.onBudget" class="web" matTooltip="Agent Contact on Budget"><mat-icon svgIcon="marker-agent"></mat-icon></span>
                      </span>
                    </div>
                    <div class="contact">
                      <div *ngFor="let contact of proforma.pfiContacts" class="contact-grid">
                        <span class="name">{{contact?.fullName ?? " No Contact Listed"}}</span>
                        <span class="email">{{contact?.workEmail ?? " No Contact Listed"}}</span>
                        <span *ngIf="contact.web" class="web" matTooltip="Contact on Website"><mat-icon svgIcon="avatar"></mat-icon></span>
                        <span *ngIf="contact?.primary ?? false" class="primary" matTooltip="Primary Contact"><mat-icon svgIcon="marker-primary"></mat-icon></span>
                      </div>
                    </div>
                  </div>
                  <p class="divide--long"></p>
                  <div class="pfi-split">
                    <div id="split-{{proforma.pfiId}}-{{pfisplit.warehouse.region}}" cdkDrag [cdkDragData] = "pfisplit" *ngFor="let pfisplit of proforma.pfiSplit | groupDispatch"
                      class="{{pfisplit.warehouse.region| lowercase | removewhitespace}}">
                      <div class="location">{{pfisplit.warehouse.region}}</div>
                      <div class="commodities">{{pfisplit.splitCommodity.letter}}</div>
                      <div class="value">{{pfisplit.warehouseValue | currency: proforma.pfiCurrency.currencyCode}}</div>
                    </div>
                  </div>
                </mat-card>
              </div>
        </div>

        <div class="dispatch-list">        
          <div *ngFor="let dispatch of client.dispatches | filterDispatch">
            <mat-card class="dispatch primary {{this.stateService.flipState$.value.get(dispatch.dispatchId)}} {{dispatchIsClosed(dispatch.isClosed)}}" *ngIf="dispatch.dispatchId"
            cdkDropList
            id = "{{addId(dispatch.dispatchId)}}"
            (cdkDropListDropped)="drop($event, dispatch)"    
            cdkDropListSortingDisabled         
            [cdkDropListData]="dispatch.dispatchProformas"
            cdkDropListConnectedTo="drop-zone"
            [attr.style]="sanitizer.bypassSecurityTrustStyle('--mb: ' + dispatch.dispatchProformas.length + ';')"
            >
              <mat-card-title>
                <div class="accountNo">{{dispatch.accountNo}}-{{dispatch.dispatchNo}}{{dispatch.dispatchNoLetter.commodityID === 2005 ? "V" : dispatch.dispatchNoLetter.letter}}</div>
                <div class="contents">
                  <button matTooltip="Dispatch Contents" class="contents" mat-icon-button aria-label="dispatch contents" (click)="sharingDispatchData(client, dispatch,2)">
                    <mat-icon svgIcon="looking-glass"></mat-icon>
                  </button>
                </div>
                <div class="iwdStatus">
                  <img *ngIf="!dispatch.closedToIwd && dispatch.hasIwd && dispatch.dispatchProformas.length > 0" src="app/coad-web/proforma-to-dispatch/images/iwdrsvg.svg">
                  <img *ngIf="dispatch.closedToIwd" src="app/coad-web/proforma-to-dispatch/images/iwdrclosedsvg.svg">
                </div>
                <div class="warehouse">{{dispatch.dispatchWarehouse.region}}</div>
                <div *ngIf="dispatch.hasIwd || dispatch.closedToIwd && dispatch.dispatchProformas.length > 0" class="flip"  >
                  <button matTooltip="Show IWDs" class="flip" mat-icon-button aria-label="iwd-flip" (click)="flip(dispatch.dispatchId)">
                    <mat-icon svgIcon="red-corner"></mat-icon>
                  </button>
                </div>
              </mat-card-title>
              
              <p class="divide--long"></p>
              <div class="mid-section">
                <div class="show-Address">
                  <div class="delivery-address address-format {{hasConsignee((dispatch.dispatchConsigneeAddress ?? dispatch.dispatchDeliveryAddress).addressId ?? 0, dispatch.dispatchDeliveryAddress.addressId)}}"><span class="description">{{dispatch.dispatchDeliveryAddress.addressDescription}}</span><br> {{dispatch.dispatchDeliveryAddress.addressDetail}}</div>
                  <div class="consignee-address address-format"><span class="description">{{(dispatch.dispatchConsigneeAddress ?? dispatch.dispatchDeliveryAddress).addressDescription}}</span><br> {{(dispatch.dispatchConsigneeAddress ?? dispatch.dispatchDeliveryAddress).addressDetail}}</div>
                </div>
                <span class="left-side">
                  <div class="method">{{dispatch.dispatchFreightMethod.name}} {{dispatch.incoterm.abbreviation}} 
                    <button matTooltip="Edit Dispatch" class="edit" mat-icon-button aria-label="edit dispatch" (click)="sharingDispatchData(client, dispatch,1)">
                      <mat-icon svgIcon="edit-pen"></mat-icon>
                    </button>
                  </div>
                  <div *ngIf="dispatch.dispatchNoLetter.commodityID != 2005" class="incoterm-location">Incoterm Location: {{dispatch.incotermLocation}}</div>
                  <div class="ets">Dispatch on: {{dispatch.dispatchEts | date: "d MMM yyyy" }}</div>
                  <div class="descriptive">Customs Descriptive: {{dispatch.descriptive?.type ?? 'None Set'}}</div>
                  <div class="primary-freight-contact">
                    Primary Freight Contact: {{dispatch.clientContact?.fullName ?? 'No Contact Listed'}}
                    <span *ngIf="dispatch.clientContact?.primary ?? false" class="primary" matTooltip="Primary Contact"><mat-icon svgIcon="marker-primary"></mat-icon></span>
                  </div>
                  <div class="completion" *ngIf="calculatePieValues(dispatch.completion) as pieValues">
                    <div class="pie">
                      <div *ngFor="let warehousePackedCount of pieValues; let i = index">
                        <div class="segment one" [attr.style]="sanitizer.bypassSecurityTrustStyle('--offset: ' + warehousePackedCount.start + ';' + '--value: ' + warehousePackedCount.packed + ';' +  '--over50: ' + warehousePackedCount.over50packed + ';' +  '--bg: ' + getColor(dispatch.completion.warehousePackedCount[i].warehouseId).get(1))"></div>
                        <div class="segment two" [attr.style]="sanitizer.bypassSecurityTrustStyle('--offset: ' + (warehousePackedCount.start + warehousePackedCount.packed) + ';' + '--value: ' + warehousePackedCount.unpacked + ';' + '--over50: ' + warehousePackedCount.over50unpacked + ';' +  '--bg: ' + getColor(dispatch.completion.warehousePackedCount[i].warehouseId).get(0))"></div>
                      </div>
                    </div>
                    <div class="chart-caption" *ngIf="getPrimary(dispatch.completion.warehousePackedCount, dispatch) as primaryShipment">
                      <div id="{{dispatch.dispatchId}}-chart-reload-false">
                        <div class="total">Recieved: {{pieValues[0]?.percentRecieved + pieValues[1]?.percentRecieved  | number : '1.2-2'}}%</div>
                        <div class="main">
                          <div class="packed">Packed: {{primaryShipment.packed}}</div>
                          <div class="unpacked">Unpacked:{{primaryShipment.unpacked}}</div>
                        </div>
                        <div *ngIf="getSecondary(dispatch.completion.warehousePackedCount, dispatch) as iwd">
                          <div class="iwd">
                            <div class="iwdpacked">IWD Packed: {{iwd.packed}}</div>
                            <div class="iwdunpacked">IWD Unpacked:{{iwd.unpacked}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="{{dispatch.dispatchId}}-chart-reload-true" [style.display]="'none'">
                      <div class="total">{{reloadState.get(dispatch.dispatchId)}} item(s) to reload</div>
                    </div>
                  </div>
                </span>
              </div>
              <button matTooltip="Show Associated Parts" class="expand off"  mat-icon-button aria-label="show associated parts" (click)="shuffle(dispatch.dispatchId)">
                <mat-icon svgIcon="expand"></mat-icon>
              </button>
              <div  class="expansion {{this.stateService.drawerState$.value.get(dispatch.dispatchId)}}">
                <div class="dispatch-split" 
                cdkDropList 
                (cdkDropListDropped)="drop($event, dispatch)"
                cdkDropListSortingDisabled 
                cdkDropListConnectedTo="drop-zone"
                [cdkDropListData]="dispatch.dispatchProformas"
                id="expansion-{{dispatch.dispatchId}}"
                >
                  <div id="dispatch-proforma-{{dispatchsplit.originPfi}}" *ngFor="let dispatchsplit of dispatch.dispatchProformas"  class="{{dispatchsplit.warehouse.region | lowercase | removewhitespace}}"
                  cdkDrag 
                  [cdkDragData]="dispatchsplit" 
                  [cdkDragDisabled]="dispatchsplit.splitPercentRecieved === 100"
                  >
                    <div class="progressBar strong" [style.width.%]="dispatchsplit.splitPercentRecieved"></div>
                    <div class="locked" *ngIf="dispatchsplit.splitPercentRecieved === 100"><img src="app/coad-web/proforma-to-dispatch/images/locksvg.svg"
                      onerror="this.src='app/coad-web/proforma-to-dispatch/images/locksvg.png'" height="16px" alt="locked"></div>
                    <div class="location text">{{dispatchsplit.originPfi}} - {{dispatchsplit.warehouse.region}}</div>
                    <div class="commodities text">{{dispatchsplit.splitCommodity.letter}}</div>
                    <div class="value text">{{dispatchsplit.warehouseValue | currency: dispatch.dispatchCurrency.currencyCode}}</div>                    
                  </div>
                </div>
                <div class="bottom-controls">
                  <div class="delete" *ngIf="canDelete(dispatch.dispatchProformas)">
                    <button matTooltip="Delete Dispatch" class="delete" mat-icon-button aria-label="delete dispatch" (click)="deleteDispatch(dispatch.dispatchId)">
                      <mat-icon svgIcon="trash-can"></mat-icon>
                    </button>                    
                    <!-- <img src="app/coad-web/proforma-to-dispatch/images/trashsvg.svg" onerror='this.src="app/coad-web/proforma-to-dispatch/images/trashsvg.png"' alt="delete"
                    height="20px" (click)="deleteDispatch(dispatch.dispatchId)"> -->
                  </div>
                  <div class="report" *ngIf="!canDelete(dispatch.dispatchProformas)">
                    <button matTooltip="Dispatch Report" class="report" mat-icon-button aria-label="delete dispatch" (click)="getSummary(dispatch)">
                      <mat-icon svgIcon="report"></mat-icon>
                    </button>
                    <!-- <img src="app/coad-web/proforma-to-dispatch/images/report-final.svg" onerror='this.src="app/coad-web/proforma-to-dispatch/images/report-final.png"' alt="report"
                    height="20px" (click)="getSummary(dispatch)"> -->
                  </div>
                  <button matTooltip="Hide Associated Parts" class="expand on"  mat-icon-button aria-label="show associated parts" (click)="shuffle(dispatch.dispatchId)">
                    <mat-icon svgIcon="expand"></mat-icon>
                  </button>
                  <div class="disassociate-all" *ngIf="canDisassociate(dispatch.dispatchProformas)">
                    <button matTooltip="Disassociate All Proformas" class="disassociate" mat-icon-button aria-label="delete dispatch" (click)="disassociate(dispatch.dispatchId)">
                      <mat-icon svgIcon="break-link"></mat-icon>
                    </button>     
                    <!-- <img src="app/coad-web/proforma-to-dispatch/images/disassociate.svg" onerror='this.src="app/coad-web/proforma-to-dispatch/images/disassociate.png"' alt="disassocate-all"
                    (click)="disassociate(dispatch.dispatchId)"> -->
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card id="dispatch-{{dispatch.dispatchId}}-secondary" class="dispatch secondary {{this.stateService.flipState$.value.get(dispatch.dispatchId)}}" *ngIf="dispatch.hasIwd || dispatch.closedToIwd"
            cdkDropList 
            (cdkDropListDropped)="drop($event, dispatch)"              
            cdkDropListSortingDisabled     
            [cdkDropListData]="dispatch.dispatchProformas"
            >
              <mat-card-title>
                <div class="accountNo">{{dispatch.accountNo}}-{{dispatch.dispatchNo}}</div>
                <div class="contents">
                  <button matTooltip="Dispatch Contents" class="contents" mat-icon-button aria-label="dispatch contents" (click)="sharingDispatchData(client, dispatch,3)">
                    <mat-icon svgIcon="looking-glass"></mat-icon>
                  </button>
                </div>
                <div class="iwdStatus">
                  <img *ngIf="!dispatch.closedToIwd && dispatch.hasIwd && dispatch.dispatchProformas.length > 0" src="app/coad-web/proforma-to-dispatch/images/iwdrsvg.svg">
                  <img *ngIf="dispatch.closedToIwd" src="app/coad-web/proforma-to-dispatch/images/iwdrclosedsvg.svg">
                </div>
                <label class="switch" matTooltip="Close Dispatch to IWD">
                  <input type="checkbox" id="iwdClosedSwitch-{{dispatch.dispatchId}}" (change)="changeIWDState(dispatch)" [checked]="dispatch.closedToIwd">
                  <span class="slider round"></span>
                </label>
                <div class="warehouse">IWD</div>
                <div *ngIf="dispatch.hasIwd || dispatch.closedToIwd && dispatch.dispatchProformas.length > 0" class="flip"  >
                  <button matTooltip="Show Dispatch" class="flip" mat-icon-button aria-label="iwd-flip" (click)="flip(dispatch.dispatchId)">
                    <mat-icon svgIcon="red-corner"></mat-icon>
                  </button>
                </div>
              </mat-card-title>
              <p class="divide--long"></p>
              <div class="iw-mid-section">
                <div class="iw-deadline">
                  <span class="text">Deadline Edit</span>
                  <button matTooltip="Edit Deadline" class="edit" mat-icon-button aria-label="edit deadline" (click)="openIWDDeadlineDialog(dispatch, dispatch.dispatchProformas)">
                    <mat-icon svgIcon="edit-pen"></mat-icon>
                  </button>
                </div>
                <div class="deadline-overview">
                  <div *ngFor="let dispatchWarehouseDetail of dispatch.dispatchWarehouseDetails; let i = index">
                    <div class="warehouse">{{dispatchWarehouseDetail.warehouse.region}}</div>
                    <div class="daterange">{{dispatchWarehouseDetail.ets | date: "d MMM yyyy"}} / {{dispatchWarehouseDetail.eta | date: "d MMM yyyy"}}</div>
                    <div class="completion" *ngIf="this.iwdReturn[getIndex(iwdReturn, dispatch.dispatchId, dispatchWarehouseDetail.warehouse.warehouseId)][1] as pieValues">
                      <div class="pie">
                        <div *ngIf="pieValues;">
                          <div class="segment one" [attr.style]="sanitizer.bypassSecurityTrustStyle('--offset: ' + pieValues.start + ';' + '--value: ' + pieValues.packed + ';' +  '--over50: ' + pieValues.over50packed + ';' +  '--bg: ' + getColor(dispatchWarehouseDetail.warehousePackedCount[0].warehouseId).get(1))"></div>
                          <div class="segment two" [attr.style]="sanitizer.bypassSecurityTrustStyle('--offset: ' + (pieValues.start + pieValues.packed) + ';' + '--value: ' + pieValues.unpacked + ';' + '--over50: ' + pieValues.over50unpacked + ';' +  '--bg: ' + getColor(dispatchWarehouseDetail.warehousePackedCount[0].warehouseId).get(0))"></div>
                        </div>
                      </div>
                      <div class="chart-caption" *ngIf="getPrimary(dispatch.completion.warehousePackedCount, dispatch) as primaryShipment">
                        <div id="{{dispatch.dispatchId}}-chart-reload-false">
                          <div class="total">Recieved: {{pieValues.percentRecieved | number : '1.2-2'}}%</div>
                          <div class="main">
                            <div class="packed">Total: {{dispatchWarehouseDetail.warehouseLineCount}}</div>
                            <div class="packed">Packed: {{dispatchWarehouseDetail.warehousePackedCount[0].packed}}</div>
                            <div class="unpacked">Unpacked:{{dispatchWarehouseDetail.warehousePackedCount[0].unpacked}}</div>
                          </div>
                        </div>
                      </div>
                      <div id="{{dispatch.dispatchId}}-chart-reload-true" [style.display]="'none'">
                        <div class="total">{{reloadState.get(dispatch.dispatchId)}} item(s) to reload</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="iw-dispatches" *ngFor="let iwdDispatch of dispatch.dispatchIwds">
                <div class="top-row">
                  <div class="accDispatchNo">{{iwdDispatch.accountNo}}-{{iwdDispatch.dispatchNo}}</div>
                  <button matTooltip="Edit IWD" class="edit" mat-icon-button aria-label="edit iwd-dispatch" (click)="openIWDDispatchDialog(dispatch, client, iwdDispatch)">
                    <mat-icon svgIcon="edit-pen"></mat-icon>
                  </button>
                  <div class="dates">ETA: {{iwdDispatch.dispatchEta | date: "d MMM yyyy"}}</div>
                  <div class="dispatchMethod">{{methodShortName(iwdDispatch.dispatchFreightMethod)}}</div>  
                </div>
                <div class="bottom-row">
                  <div class="progressBar" [style.width.%] = "iwdDispatch.completion.DispatchPercRec"></div>
                  <div class="progressPercent">
                    {{iwdDispatch.completion.dispatchPercRec}}%
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        <div class="add" 
          cdkDropList
          id="{{addId(0)}}"
          [cdkDropListData] = "dispatchDropCreateArray"
          cdkDropListConnectedTo="drop-zone"
          (cdkDropListDropped)="dropCreate($event, client, 1)" >
            <div class="dispatch mat-elevation-z4" (click)="sharingDispatchData(client,{},1)">
            <div class="text">+</div>
          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

